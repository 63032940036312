<template>
  <div class="about-section__user">
    <div class="about-section___profile-pic-wrapper">
      <picture>
        <source
          :srcset="require(`@/assets/images/aboutus/${profile.path}.jpg`)"
          type="image/jpeg"
        />
        <img
          class="about-section__profile-pic"
          :src="require(`@/assets/images/aboutus/${profile.path}.jpg`)"
          alt="project__pic"
        />
      </picture>
    </div>
    <p><b>{{profile.name}}</b></p>
    <p>{{profile.role}}</p>
  </div>
</template>
<script>
export default {
  props: ["profile"],
}
</script>
<style lang="scss" scoped>

.about-section__user {
  padding: 30px 10px;
  font-size: 13.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
}
.about-section___profile-pic-wrapper {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: grey;
}
.about-section__profile-pic {
  width: 120px;
  border-radius: 50%;
}
@media only screen and (min-width: 1024px) {
  .about-section__user {
    padding: 30px 100px;
  }
}
</style>
