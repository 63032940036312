<template>
  <div class="namecard">
    <NameCard :profile="profile"/>
  </div>
</template>
<script>
import NameCard from "@/components/namecard/NameCard.vue";
export default {
  components: {
    NameCard,
  },
  data() {
    return {
      profile:
        {
          id: 1,
          name: "Jayden Lim",
          path: "jayden",
          role: "Co-Founder, Operation Director",
          email: "jaydenlim@WebBricksSG.com",
          phone: "+65 8342 4727",
          linkedin:"https://www.linkedin.com/in/jowett-chng-394ab2156/",
          facebook: "https://www.facebook.com/lim.ting.940",
          instagram: "https://www.instagram.com/jowett_chng",
        },
    }
  }
};
</script>
