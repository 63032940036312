import { render, staticRenderFns } from "./ProfileComponent.vue?vue&type=template&id=05619cca&scoped=true"
import script from "./ProfileComponent.vue?vue&type=script&lang=js"
export * from "./ProfileComponent.vue?vue&type=script&lang=js"
import style0 from "./ProfileComponent.vue?vue&type=style&index=0&id=05619cca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05619cca",
  null
  
)

export default component.exports