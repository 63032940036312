<template>
  <section class="namecard__section">
    <div class="namecard-pic__container">
      <ProfileComponent :profile="profile" style="font-size: 19px !important" />
    </div>
    <div class="logo-socials">
      <div class="logo-socials__socials">
        <a :href="profile.instagram" target="_blank" rel="noopener noreferrer">
          <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
          <span>Instagram</span>
        </a>
        <a :href="profile.facebook" target="_blank" rel="noopener noreferrer">
          <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
          <span>Facebook</span>
        </a>
        <a :href="profile.linkedin" target="_blank" rel="noopener noreferrer">
          <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
          <span>LinkedIn</span>
        </a>
        <!-- <a
            href="https://www.twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
            alt="twitter"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" size="2x" />
            <span>Twitter</span>
          </a> -->
      </div>
    </div>
    <div class="namecard__contactInfo">
      <b>{{ profile.email }}</b>
      <br />
      <br />
      <b>{{ profile.phone }}</b>
    </div>
  </section>
</template>
<script>
import ProfileComponent from "@/components/about/ProfileComponent.vue";
export default {
  props: ["name", "path", "profile", "details"],
  components: {
    ProfileComponent,
  },
  metaInfo() {
    return {
      title: this.profile.name,
    };
  },
};
</script>
<style lang="scss" scoped>
.namecard__section {
  padding-top: 100px;
  background-color: white;
}
.namecard-pic__container div {
  margin: auto;
}
.footer {
  padding-top: 5rem;
  &__curve img {
    width: 100%;
    margin-bottom: -5px;
  }

  &__information {
    color: #37484f;
    background-color: #37484f;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 15rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 8rem;
    }
  }
}

.logo-socials {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 392px;
  color: #37484f;
  &__company {
    img {
      width: 100%;
      height: auto !important;

      @media only screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
  &__socials {
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;

    span {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      border: 0;
    }

    svg {
      color: #37484f;
    }
  }
}
.namecard__contactInfo {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}
</style>
