<template>
  <div class="namecard">
    <NameCard :profile="profile"/>
  </div>
</template>
<script>
import NameCard from "@/components/namecard/NameCard.vue";
export default {
  components: {
    NameCard,
  },
  data() {
    return {
      profile:
        {
          id: 1,
          name: "Gerard Nagu",
          path: "gerard",
          role: "Chief Designer",
        //   email: "Jowettchng@WebBricksSG.com",
          phone: "+65 9177 3336",
          linkedin:"https://www.linkedin.com/in/jowett-chng-394ab2156/",
          facebook: "https://www.facebook.com/gerard.nagulendran",
          instagram: "https://www.instagram.com/jowett_chng",
        },
    }
  }
};
</script>
